@font-face {
  font-family: "Benton Sans";
  src: url(/fonts/BentonSansForOmni-Regular.woff);
  font-weight: normal;
}

@font-face {
  font-family: "Benton Sans";
  src: url(/fonts/BentonSansForOmni-Medium.woff);
  font-weight: 600;
}

@font-face {
  font-family: "Benton Sans";
  src: url(/fonts/BentonSansForOmni-Bold.woff);
  font-weight: 900;
}

@font-face {
  font-family: "Benton Sans Display";
  src: url(/fonts/BentonSansForOmniDisplay-Medium.woff);
  font-weight: 500;
}

@font-face {
  font-family: "Publico Headline";
  src: url("/fonts/PublicoHeadline-Medium-Web.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Publico Headline";
  src: url("/fonts/PublicoHeadline-Roman.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Publico Text";
  src: url("/fonts/PublicoText-Roman-Web.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "Publico Text";
  src: url("/fonts/PublicoText-Bold.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "National";
  src: url("/fonts/NationalLP-Regular.otf");
  font-weight: normal;
  ascent-override: 85%; // Compensate for line height weirdness of this font (not supported in safari nov 2023)
}

@font-face {
  font-family: "National";
  src: url("/fonts/NationalLP-Medium.otf");
  font-weight: 600;
  ascent-override: 85%; // Compensate for line height weirdness of this font (not supported in safari nov 2023)
}
